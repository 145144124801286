<template>
    <div class="setting-container">
        <div class="main">
            <van-cell-group>
                <van-cell title="登录账户" :value="info.username||'未设置'"/>
                <van-cell title="真实姓名" :value="info.truename||'未设置'" is-link @click="show.info=true"/>
                <van-cell title="邮箱" :value="info.mail||'未设置'" is-link @click="show.info=true"/>
                <van-cell title="手机号" :value="info.phone||'未设置'" is-link @click="show.info=true"/>
            </van-cell-group>
            <van-cell-group>
                <van-cell title="修改密码" is-link @click="show.password=true"/>
            </van-cell-group>

            <div class="setting-container-btn">
                <van-button type="default" size="large" @click="logout" icon="my iconfont icon-tuichu">退出登录</van-button>
            </div>

            <div class="setting-container-btn">
                <van-button type="default" size="large" @click="reload" icon="my iconfont icon-chongzai" :loading="loading" loading-type="spinner" loading-text="重载中...">重载权限</van-button>
            </div>
        </div>
        <div class="popup-group">
            <van-popup v-model="show.info" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <info/>
            </van-popup>
            <van-popup v-model="show.password" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <password/>
            </van-popup>
        </div>
    </div>
</template>
<script>
    import {mapMutations} from 'vuex'
    import store from 'store'
    import info from './setting/info'
    import password from './setting/password'

    export default {
        name: 'setting',
        props: [],
        components: {
            info,
            password
        },
        data() {
            return {
                info: this.$store.state.user.info,
                show: {
                    info: false,
                    password: false,
                },
                loading: false
            }
        },
        beforeRouteEnter(to, from, next) {
            window.document.body.style.background = '#efeff4'
            next()
        },
        beforeRouteLeave(to, from, next) {
            window.document.body.style.background = '#ffffff'
            next()
        },
        mounted: function () {

        },
        activated: function () {

        },
        methods: {
            ...mapMutations(['changeAuth']),
            reload() {
                let that = this;
                that.loading = true;
                that.$api.node.loadAuth().then(response => {
                    that.loading = false;
                    let res = response.data;
                    if (res.code == 1) {
                        that.changeAuth(res.data);
                        that.$toast.success(res.msg);
                    } else {
                        that.$toast.fail(res.msg);
                    }
                });
            },
            logout() {
                let that = this;
                that.$api.user.logout().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        store.remove('token');
                        store.remove('userauth');
                        store.remove('userinfo');
                        that.$notify({type: 'primary', message: res.msg})
                        that.$router.push({
                            path: '/login',
                            query: {
                                //redirect: that.$router.currentRoute.fullPath
                            }
                        })
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            }
        },
        watch: {}
    }
</script>
<style scoped>
    .setting-container {
        background-color: #f5f5f5;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .setting-container .van-cell-group {
        margin-bottom: 10px;
    }

    .setting-container-btn {
        margin-bottom: 10px;
    }

    .van-cell__value{
        flex: 3;
    }
</style>
