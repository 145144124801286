<template>
    <div class="setting-info-container">
        <van-nav-bar title="设置个人信息"/>
        <div class="setting-info-form">
            <van-cell-group>
                <van-field
                        v-model="info.truename"
                        clearable
                        label="真实姓名"
                        maxlength="10"
                        placeholder="请输入真实姓名"
                        :show-word-limit="false"
                />
                <van-field
                        v-model="info.mail"
                        clearable
                        type="mail"
                        label="邮箱"
                        maxlength="50"
                        placeholder="请输入邮箱"
                        :show-word-limit="false"
                />
                <van-field
                        v-model="info.phone"
                        clearable
                        type="tel"
                        maxlength="11"
                        label="手机号"
                        placeholder="请输入手机号"
                        :show-word-limit="false"
                />
            </van-cell-group>
        </div>
        <div class="setting-info-btn">
            <van-button type="info" size="large" :disabled="!submitEnalbe" @click="submit">确定</van-button>
        </div>
    </div>
</template>
<script>

    import {mapMutations} from 'vuex'

    export default {
        name: 'setting_password',
        data() {
            return {
                info: this.$store.state.user.info,
                submitEnalbe: false
            }
        },
        mounted: function () {

        },
        activated: function () {

        },
        methods: {
            ...mapMutations(['changeUserInfo']),
            submit() {
                let that = this
                if (!that.submitEnalbe) return false
                that.$api.user.setInfo(that.info).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.changeUserInfo(that.info)
                        that.$notify({type: 'primary', message: res.msg})
                        that.$router.push({
                            path: '/setting'
                        })
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            }
        },
        watch: {
            info: {
                handler: function (info) {
                    if (info.mail.length > 0 && info.truename.length > 0 && info.phone.length > 0) {
                        this.submitEnalbe = true
                    } else {
                        this.submitEnalbe = false
                    }
                },
                deep: true
            }
        }
    }
</script>
<style scoped>

    .setting-info-form {

    }

    .setting-info-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

</style>
