<template>
    <div class="my-app-container">
        <div class="my-app-list" v-if='list.length > 0'>
            <div class="my-app-grid">
                <van-grid clickable :column-num="4" :border="false">
                    <van-grid-item v-for="(item, index) in list" v-bind:key="index" dot
                                   :icon="item.icon"
                                   :text="item.title"
                                   info=""
                                   :clickable="true"
                                   @click="start(item,index)"
                                   @touchstart.native="touchstart(item,index)"
                                   @touchmove.native="touchmove()"
                                   @touchend.native="touchend()"
                                   icon-size="30px"
                    />
                </van-grid>
            </div>
            <div class="my-app-apps">
                <div class="my-app-apps-item" v-for="(item, index) in list" v-bind:key="index">
                    <div class="my-app-apps-item-container">
                        <van-popup v-model="item.show" position="bottom" closeable close-icon="my iconfont icon-guanbi"
                                   :style="{width:'100%',height:'100%'}" get-container="#popup-app">
                            <dynamicLink :node="item" :key="item.id" @onClose="onClose"/>
                        </van-popup>
                    </div>
                </div>
            </div>
            <div class="my-app-remove-popup">
                <van-popup v-model="removePopup" :round="true" :close-on-click-overlay="false">
                    <van-button size="small" type="danger" icon="delete" @click="remove">移除</van-button>
                    <van-button size="small" type="primary" icon="cross" @click="cancel">取消</van-button>
                </van-popup>
            </div>
        </div>
        <div class="my-app-add" v-if='list.length == 0' @click="addTip">
            <van-icon name="plus" size="30px"/>
            <div>添加</div>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'
    import dynamicLink from './dynamicLink'

    export default {
        name: 'my-app',
        props: ['pageId'],
        components: {
            dynamicLink
        },
        data() {
            return {
                list: this.$store.state.user.apps,
                node: {
                    id: 0,
                    pid: 0
                },
                timeOutEvent: 0,
                removeIndex: 0,
                removeNode: null,
                removePopup: false
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            ...mapMutations(['changeUserApps']),
            init() {
                let that = this;
                // 加载我的应用
                that.$api.node.loadFavorite().then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.list = res.data
                        that.changeUserApps(res.data)
                    }
                })
            },
            start(node, index) {
                node.show = true
                this.list[index] = node
            },
            touchstart(node, index) {
                let that = this;
                clearTimeout(that.timeOutEvent) // 清除定时器
                that.timeOutEvent = 0
                that.timeOutEvent = setTimeout(function () {
                    that.removeNode = node
                    that.removeIndex = index
                    that.removePopup = true
                }, 1000)
            },
            touchmove() {
                clearTimeout(this.timeOutEvent) // 清除定时器
                this.timeOutEvent = 0
            },
            touchend() {
                clearTimeout(this.timeOutEvent) // 清除定时器
                this.timeOutEvent = 0
            },
            remove() {
                let that = this
                that.removePopup = false
                that.$api.node.removeFavorite(that.removeNode).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.list.splice(that.removeIndex, 1)
                        that.$notify({type: 'primary', message: res.msg})
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            },
            addTip() {
                this.$dialog.alert({
                    message: '1、添加应用：请从“应用分类”查找【带有红点的图标】长按进行添加；<br><br>2、移除应用：从“我的应用”中长按应用图标进行移除；',
                    messageAlign: 'left'
                })
            },
            cancel() {
                this.removePopup = false
            },
            onClose(node) {
                let that = this;
                that.list.forEach(function (v, k) {
                    if (v.id == node.id) {
                        that.list[k].show = false;
                    }
                });
            }
        },
        watch: {
            pageId: function () {
                this.init()
            }
        }
    }
</script>

<style scoped>
    .my-app-container {

    }

    .my-app-add {
        border: 1px dashed #d5d5d5;
        padding: 12px;
        text-align: center;
        border-radius: 5px;
        margin: 10px;
        font-size: 14px;
        color: #333333;
    }

    .my-app-add .van-icon-plus {
        color: #bbbbbb;
    }

    .van-popup--round {
        background: none;
    }
</style>
