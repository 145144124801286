<template>
    <component :is="component" v-if="component" :node="node" :param="param" :key="node.id" @onClose="onClose"/>
</template>
<script>
    export default {
        name: 'dynamic-link',
        props: ['node', 'param'],
        data() {
            return {
                app: this.node,
                component: null
            }
        },
        computed: {
            loader() {
                if (!this.app.id) {
                    return null
                }
                return () => import(`.${this.app.node}`)
            },
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('./error')
                })
        },
        methods: {
            onClose(node) {
                this.$emit('onClose', node)
            }
        }
    }
</script>
