<template>
    <div>
        <div class="app-nodes" v-if='list.length > 0'>
            <div class="app-nodes-item" v-for="(item, index) in list" v-bind:key="index">
                <div class="app-nodes-item-container" v-for="(node, key) in item.list" v-bind:key="key">
                    <van-popup v-model="node.show" position="bottom" closeable close-icon="close"
                               :style="{width:'100%',height:'100%'}" get-container="#popup-app">
                        <dynamicLink :node="node" :key="node.id" @onClose="onClose"/>
                    </van-popup>
                </div>
            </div>
        </div>
        <div class="search-container">
            <div class="search-bar">
                <van-search v-model="keyword" placeholder="搜索一下" show-action shape="round" @search="search">
                    <div slot="action" @click="search">搜索</div>
                </van-search>
            </div>
            <div class="index-bar">
                <van-index-bar v-if="list.length > 0" :index-list="indexList" highlight-color="#108de9">
                    <template v-for="(item, index) in list">
                        <van-index-anchor :index="item.index" v-bind:key="index"/>
                        <template v-for="(node, key) in item.list">
                            <van-cell v-bind:key="key" :title="node.title" @click="start(node,index,key)"/>
                        </template>
                    </template>
                </van-index-bar>
            </div>
        </div>
    </div>
</template>

<script>
    import dynamicLink from './dynamicLink'

    export default {
        name: 'search',
        components: {
            dynamicLink
        },
        data() {
            return {
                keyword: '',
                list: [],
                indexList: []
            }
        },
        mounted: function () {

        },
        activated: function () {

        },
        methods: {
            start(node, index, key) {
                node.show = true
                this.list[index][key] = node
            },
            search() {
                let that = this
                that.$api.node.search(that.keyword).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.indexList = []
                        for (var i = 0, len = res.data.length; i < len; i++) {
                            that.indexList.push(res.data[i].index)
                        }
                        that.list = res.data
                    } else {
                        that.$toast.fail(res.msg)
                    }
                })
            },
            onClose(node) {
                let that = this;
                that.list.forEach(function (v, k) {
                    if (v.id == node.id) {
                        that.list[k].show = false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .search-container {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

    .search-bar {
        right: 0;
        left: 35px;
        position: fixed;
        top: 0;
        background-color: #ffffff;
    }

    .app-nodes {

    }

    .index-bar {
        text-align: left;
        text-indent: .5rem;
        top: 45px;
        bottom: 0;
        position: fixed;
        width: 100%;
        overflow-y: auto;
    }
</style>
