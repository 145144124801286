<template>
    <div class="message-container">
        <van-pull-refresh v-model="freshing" @refresh="onRefresh">
            <div class="message-list">
                <van-list v-model="loading" :error="noData" error-text="暂无消息" :finished="finished"
                          finished-text="我是有底线的" :immediate-check="false" :offset="50" @load="onLoad">
                    <div v-if="list.length > 0">
                        <div class="message-item" v-for="(item, index) in list" v-bind:key="index"
                             @click="doMessage(item,index)">
                            <van-cell :title="item.title"
                                      :icon="item.type==2 ? 'my iconfont icon-tongguo' : 'my iconfont icon-message'"
                                      :value="item.create_at">
                                <van-icon v-if="item.status==0" slot="right-icon" name="my iconfont icon-dian"
                                          style="line-height: inherit;" color="red"/>
                            </van-cell>
                            <div class="message-item-body" v-html="item.message"/>
                            <van-cell title="查看详情" is-link/>
                        </div>
                    </div>
                </van-list>
            </div>
        </van-pull-refresh>
        <van-popup v-model="node.show" position="bottom" closeable close-icon="my iconfont icon-guanbi"
                   :style="{width:'100%',height:'100%'}" get-container="#popup-app">
            <dynamicLink :node.sync="node" :key="node.id" :param.sync="node.param" @onClose="onClose"
                         v-if="node.id > 0"/>
        </van-popup>
    </div>
</template>

<script>

    import marked from 'marked'
    import {mapMutations} from 'vuex'
    import dynamicLink from './dynamicLink'

    export default {
        name: 'message',
        components: {
            dynamicLink
        },
        data() {
            return {
                list: [],
                freshing: false,
                loading: false,
                noData: false,
                finished: false,
                option: {
                    page: 0
                },
                pages: 0,
                messageId: this.$route.query.messageId || 0,
                node: {
                    id: 0,
                    node: '',
                    param: {},
                    show: false,
                },
            };
        },
        computed: {},
        mounted: function () {
            if (this.messageId) {
                this.loadMessage();
            }
            this.onLoad();
        },
        methods: {
            ...mapMutations(['changeUserMessage', 'decUserMessage']),
            onRefresh() {
                setTimeout(() => {
                    let that = this;
                    that.freshing = true;
                    that.finished = false;
                    that.noData = false;
                    that.option.page = 0;
                    that.list = [];
                    that.onLoad();
                }, 500);
            },
            onLoad() {
                let that = this;
                that.freshing = false;
                if (that.finished) {
                    return false;
                }
                that.loading = true;
                that.noData = false;
                that.option.page += 1;
                that.$api.message.load(that.option).then(respond => {
                    that.loading = false;
                    let res = respond.data;
                    if (res.code == 1) {
                        if (res.data.list.length > 0) {
                            that.list.push(...that.compiledMarkdown(res.data.list));
                        }
                        that.pages = res.data.pages;
                        that.page = res.data.page;
                        that.changeUserMessage(res.data.count);
                    }
                    if (that.pages > 0) {
                        if (that.page >= that.pages) {
                            that.finished = true;
                        }
                    } else {
                        that.noData = true;
                    }
                })
            },
            compiledMarkdown(list) {
                list.forEach(item => {
                    let message = item.message.replace(/\\n/g, '\n');
                    item.message = marked(message, {sanitize: true});
                });
                return list;
            },
            doMessage(item, index) {
                let that = this;
                that.openNode(item);
                that.$api.message.read(item).then(respond => {
                    let res = respond.data;
                    if (res.code == 1) {
                        that.list[index].status = 1;
                        that.decUserMessage();
                    }
                });
            },
            loadMessage() {
                let that = this;
                that.$api.message.detail(that.messageId).then(respond => {
                    let res = respond.data;
                    if (res.code == 1) {
                        that.openNode(res.data);
                    }
                });
            },
            openNode(item) {
                if (item.node) {
                    this.node.param = item.param;
                    this.node.id = item.id;
                    this.node.node = item.node;
                    this.node.show = true;
                }
            },
            onClose() {
                this.node.show = false;
            }
        }
    }
</script>

<style scoped>

    .message-container {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 50px;
        background-color: #f5f5f5;
        overflow-y: scroll;
    }

    .message-list {
        padding: 10px;
        min-height: 480px;
    }

    .message-item {
        margin-bottom: 10px;
        border-radius: 10px;
    }

    .message-item:last-child {
        margin-bottom: 0;
    }

    .message-item-body {
        position: relative;
        background-color: #fff;
        padding: 10px 16px;
        overflow: hidden;
        color: #323233;
        font-size: 14px;
        line-height: 24px;
        border-top: 1px solid #ebedf0;
        border-bottom: 1px solid #ebedf0;
    }

    .van-cell .van-icon.icon-dian {
        margin-left: 5px;
    }

    .van-cell__value {
        flex: 2;
    }

    .van-cell:not(:last-child)::after {
        border: none
    }

    .icon-message {
        color: #ff976a;
    }

    .icon-tongguo {
        color: #07c160;
    }

</style>
