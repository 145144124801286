<template>
    <div class="home-container">
        <div class="apps">
            <!-- 目录 -->
            <div class="list-container" v-show="active=='list'">
                <div class="search-bar">
                    <van-search placeholder="搜索一下" shape="round" background="#ffffff" disabled
                                @click="showSearch=true"/>
                </div>
                <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                    <div class="swipe-list" style="display: none">
                        <van-swipe indicator-color="white" :touchable="true">
                            <van-swipe-item v-for="(item, index) in swipeList" v-bind:key="index">
                                <img :src="item.url"/>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                    <div class="app-list">
                        <div class="my-app">
                            <van-divider :style="{ color: '#999999', borderColor: '#999999', padding: '0 12px' }">我的应用
                            </van-divider>
                            <myapp :pageId.sync="pageId"/>
                        </div>
                        <div class="more-app">
                            <van-divider :style="{ color: '#999999', borderColor: '#999999', padding: '0 12px' }">应用分类
                            </van-divider>
                            <div class="system-apps">
                                <nodes :pageId.sync="pageId"/>
                            </div>
                        </div>
                    </div>
                </van-pull-refresh>
            </div>
            <!-- 消息 -->
            <message v-show="active=='message'"></message>
            <!-- 设置 -->
            <setting v-show="active=='setting'"></setting>
        </div>
        <div class="tabbar">
            <van-tabbar v-model="active">
                <van-tabbar-item :icon="active == 'list' ? 'wap-home':'wap-home-o'" name="list">首页</van-tabbar-item>
                <van-tabbar-item :icon="active == 'message' ? 'chat':'chat-o'" name="message"
                                 :info="$store.state.user.messageCount > 0 ? ($store.state.user.messageCount > 99 ? '99+' : $store.state.user.messageCount) : ''">
                    消息
                </van-tabbar-item>
                <van-tabbar-item :icon="active == 'setting' ? 'manager':'manager-o'" name="setting">我的</van-tabbar-item>
            </van-tabbar>
        </div>
        <div class="popup-group">
            <van-popup v-model="showSearch" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}" get-container="#popup-app">
                <search/>
            </van-popup>
        </div>
        <van-overlay :show="freshing">
            <van-loading/>
        </van-overlay>
    </div>
</template>

<script>
    import setting from './setting'
    import search from './search'
    import message from './message'
    import nodes from './nodes'
    import myapp from './myapp'

    export default {
        name: 'home',
        components: {
            setting,
            search,
            message,
            nodes,
            myapp,
        },
        data() {
            return {
                freshing: false,
                pageId: 1,
                active: 'list',
                timeOutEvent: 0,
                currentNode: {
                    'id': 0,
                    'title': ''
                },
                apps: {
                    setting: false,
                    search: false,
                },
                showSearch: false,
                swipeList: [
                    {
                        url: 'https://static.mcake.com/file/c50ffe23a6958b7f/c68338400421321d.jpg'
                    }
                ]
            }
        },
        methods: {
            search() {
                this.$router.push({
                    path: '/search',
                    query: {
                        redirect: this.$router.currentRoute.fullPath
                    }
                })
            },
            next(node) {
                if (node.type == 1) { // 下钻
                    this.$router.push({
                        path: '/list',
                        query: {
                            pid: node.id,
                            title: node.title,
                            //redirect: this.$router.currentRoute.fullPath
                        }
                    })
                } else { // 切换路由
                    this.$router.push({
                        path: node.node,
                        query: {
                            //redirect: this.$router.currentRoute.fullPath
                        }
                    })
                }
            },
            onRefresh() {
                this.pageId += 1;
                setTimeout(() => {
                    this.$toast('刷新成功');
                    this.freshing = false;
                }, 500);
            }
        }
    }
</script>

<style scoped>
    .home-container {
        padding-bottom: 50px;
    }

    .home-container .list-container {
        padding-top: 54px;
    }

    .home-container .list-container .search-bar {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 2;
    }

    .home-container .list-container .swipe-list {
        padding: 0 12px;
        height: 180px;
    }

    .home-container .list-container .swipe-list img {
        width: 100%;
        height: 180px;
        border-radius: 10px;
    }

    .home-container .list-container .app-list {

    }

    .van-overlay {
        text-align: center;
    }

    .van-loading {
        color: #c8c9cc;
        font-size: 0;
        top: 50%;
        margin-top: -15px;
    }
</style>
