<template>
    <div class="setting-password-container">
        <van-nav-bar title="设置密码" />
        <div class="setting-password-form">
            <van-cell-group>
                <van-field
                        v-model="info.oldpassword"
                        type="password"
                        clearable
                        label="原密码"
                        placeholder="请输入原密码"
                        maxlength="30"
                />
                <van-field
                        v-model="info.newpassword"
                        type="password"
                        clearable
                        label="新密码"
                        placeholder="请输入新密码"
                        maxlength="30"
                />
                <van-field
                        v-model="info.confirmpassword"
                        type="password"
                        clearable
                        label="确认密码"
                        placeholder="请输入确认密码"
                        maxlength="30"
                />
            </van-cell-group>
        </div>
        <div class="setting-password-btn">
            <van-button type="info" size="large" :disabled="!submitEnalbe" @click="submit">确定</van-button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'setting_password',
        data() {
            return {
                info: {
                    oldpassword: '',
                    newpassword: '',
                    confirmpassword: ''
                },
                submitEnalbe: false
            }
        },
        mounted: function () {

        },
        activated: function () {
            this.info = {
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            }
            this.submitEnalbe = false
        },
        methods: {
            submit() {
                let that = this
                if (!that.submitEnalbe) return false
                that.$api.user.setPassword({
                    oldpassword: that.info.oldpassword,
                    newpassword: that.info.newpassword,
                    confirmpassword: that.info.confirmpassword
                }).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.$notify({type: 'primary', message: res.msg})
                        that.$router.push({
                            path: '/setting'
                        })
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            }
        },
        watch: {
            info: {
                handler: function (val) {
                    if (val.oldpassword.length > 0 && val.newpassword.length > 0 && val.confirmpassword.length > 0) {
                        this.submitEnalbe = true
                    } else {
                        this.submitEnalbe = false
                    }
                },
                deep: true
            }
        }
    }
</script>
<style scoped>
    .setting-password-form{

    }

    .setting-password-btn{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
</style>
