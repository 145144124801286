<template>
    <div class="nodes-container">
        <div class="nodes-nodes" v-if='list.length > 0'>
            <van-grid clickable :column-num="4" :border="false">
                <van-grid-item v-for="(item, index) in formList" v-bind:key="index" :dot="!item.list"
                               :icon="item.icon"
                               :text="item.title"
                               info=""
                               :clickable="true"
                               @click="next(item,index)"
                               @touchstart.native="touchstart(item)"
                               @touchmove.native="touchmove()"
                               @touchend.native="touchend()"
                               icon-size="30px"
                />
                <van-grid-item v-if="node.id > 0" icon="my iconfont icon-fanhui" icon-size="30px" text="返回上级"
                               :clickable="true"
                               @click="last"/>
            </van-grid>
        </div>
        <van-popup v-model="favoritePopup" :round="true" :close-on-click-overlay="false">
            <van-button size="small" type="info" icon="plus" @click="favorite">添加</van-button>
            <van-button size="small" type="primary" icon="cross" @click="cancel">取消</van-button>
        </van-popup>
        <div class="nodes-apps" v-if='list.length > 0'>
            <div class="nodes-apps-item" v-for="(item, index) in list" v-bind:key="index">
                <div class="nodes-apps-item-container">
                    <van-popup v-model="item.show" position="bottom" closeable close-icon="my iconfont icon-guanbi"
                               :style="{width:'100%',height:'100%'}" get-container="#popup-app">
                        <dynamicLink :node="item" :key="item.id" @onClose="onClose"/>
                    </van-popup>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapMutations} from 'vuex'

    import dynamicLink from './dynamicLink'

    export default {
        name: 'nodes',
        props: ['pageId'],
        components: {
            dynamicLink
        },
        data() {
            return {
                list: this.$store.state.root.nodes,
                node: {
                    id: 0,
                    pid: 0
                },
                timeOutEvent: 0,
                favoriteNode: null,
                favoritePopup: false
            }
        },
        mounted: function () {
            this.init();
        },
        computed: {
            formList: function () {
                return this.list.filter(function (item) {
                    return item.type > 0;
                });
            }
        },
        methods: {
            ...mapMutations(['addUserApps', 'changeRootNodes']),
            init() {
                // 加载更多应用
                let that = this;
                that.$api.node.load().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.list = res.data;
                        that.changeRootNodes(res.data);
                    }
                })
            },
            next(node, index) {
                if (node.list) { // 下钻
                    node.show = false;
                    this.node = node;
                    this.list = node.list
                } else { // 弹出app
                    node.show = true;
                    this.list[index] = node
                }
            },
            last() {
                if (this.node.pid == 0) {
                    this.node = {
                        id: 0,
                        pid: 0
                    };
                    this.list = this.$store.state.root.nodes
                } else {
                    let that = this;
                    that.$store.state.root.nodes.forEach(function (v) {
                        if (v.id == that.node.pid) {
                            that.node = v;
                            that.list = v.list;
                        }
                    })
                }
            },
            touchstart(node) {
                let that = this;
                clearTimeout(that.timeOutEvent); // 清除定时器
                that.timeOutEvent = 0;
                that.timeOutEvent = setTimeout(function () {
                    if (!node.list) {
                        that.favoriteNode = node;
                        that.favoritePopup = true;
                    }
                }, 1000);
            },
            touchmove() {
                clearTimeout(this.timeOutEvent); // 清除定时器
                this.timeOutEvent = 0;
            },
            touchend() {
                clearTimeout(this.timeOutEvent); // 清除定时器
                this.timeOutEvent = 0;
            },
            favorite() {
                let that = this;
                that.favoritePopup = false;
                that.$api.node.addFavorite(that.favoriteNode).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.addUserApps(that.favoriteNode);
                        that.$notify({type: 'primary', message: res.msg});
                    } else {
                        that.$notify({type: 'warning', message: res.msg});
                    }
                })
            },
            cancel() {
                this.favoritePopup = false;
            },
            onClose(node) {
                let that = this;
                that.list.forEach(function (v, k) {
                    if (v.id == node.id) {
                        that.list[k].show = false;
                    }
                });
            }
        },
        watch: {
            pageId: function () {
                this.init()
            }
        }
    }
</script>

<style scoped>
    .nodes-container {

    }

    .nodes-nodes {

    }

    .van-popup--round {
        background: none;
    }
</style>
